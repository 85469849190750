import * as React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import "../styles/app.scss";

const Container = styled.div`
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  h1 {
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  a,
  a:hover {
    color: #61dafb;
  }
`;

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Coming Soon | Superior Shuttle</title>
        <meta
          name="description"
          content="Airport transport, door to door shuttle, taxi, ferry transport. Free Quote. Call Us."
        />
      </Helmet>
      <Container>
        <h1>Website coming soon!</h1>
        <h3>Contact us for a free quote:</h3>
        <p>
          Call us on: <a href="tel:+64226959143">+64 22 695 9143</a>
        </p>
        <p>
          Email us on:{" "}
          <a href="mailto:info@superiorshuttle.co.nz?subject=Shuttle Request Quote">
            info@superiorshuttle.co.nz
          </a>
        </p>
      </Container>
    </>
  );
};

export default IndexPage;
